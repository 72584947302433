import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  IconButton,
  Link,
  TextFieldProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { spacing, SpacingProps, useTheme } from '@mui/system';
import * as Yup from 'yup';
import { Field, Formik, FormikProps, FormikValues } from 'formik';
import { CREATE_LEAD } from '../queries/crm/lead';
import { useSnackbar } from '../contexts/SnackbarContext';
import { useMutation, useQuery } from '@apollo/client';
import { CrmCreateLead } from '../types/crm/lead';
import { AuthContext } from '../../contexts/CognitoContext';
import FormikAutoComplete, { FormikAutocompleteOptionTypes } from '../components/formElements/FormikAutoComplete';
import { CrmInterestTypes } from '../types/crm/interestType';

import { CrmRoofTypes } from '../types/crm/roofType';
import { FormikTextField } from '../components/formElements/FormikTextField';
import LeadMap, { GoogleMapPositionTypes, LeadMapProps } from './task/components/LeadMap';
import FormikGoogleMapsSearchBox from '../components/formElements/FormikGoogleMapsSearchBox';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { AddressResult, getMapAddress } from '../types/map';
import FormikCheckbox from '../components/formElements/FormikCheckbox';
import NucleusLoader from '../NucleusLoader';
import { stringFormatter } from '../components/StringFormatter';
import { GET_INTEREST_TYPES_NEW_LEADS, GET_LEADS_LIST, GET_ROOF_TYPES_NEW_LEADS } from '../../common/graphQL';
import PhoneNumberInput from '../components/formElements/FormikPhoneNumberInput';
import { matchIsValidTel } from 'mui-tel-input';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const libraries: Libraries | undefined = ['places'];

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Grid = styled(MuiGrid)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

type CreateNewLeadFormFormikFieldTypes = {
  firstName: string;
  lastName: string;
  email: string;
  interestType: FormikAutocompleteOptionTypes | null;
  roofType: FormikAutocompleteOptionTypes | null;
  contactNo: string;
  zipCode: string;
  propertyAddress: string;
  hasTaskSequence: boolean;
  taskScheduleScaffoldGroup: FormikAutocompleteOptionTypes | null;
  submit: string;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

function PageHeader() {
  return (
    <div>
      <Helmet title="Create Lead" />
      <Typography variant="h3" gutterBottom display="inline">
        New Lead
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/crm/leads">
          Leads
        </Link>
        <Typography>New Lead</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}></Grid>
      </Grid>
    </div>
  );
}

function LeadForm() {
  const { toggleState, setMessage } = useSnackbar();
  const [createLead] = useMutation<CrmCreateLead>(CREATE_LEAD, {
    refetchQueries: [{ query: GET_LEADS_LIST }],
    awaitRefetchQueries: true,
  });
  const { user } = useContext(AuthContext) || {};
  const userID = user && user['custom:nucleus-id'];
  const [interestTypeFieldOptions, setInterestTypeFieldOptions] = useState<FormikAutocompleteOptionTypes[]>([]);
  const [selectedInterestType, setSelectedInterestType] = useState<FormikAutocompleteOptionTypes>();
  const [roofTypeFieldOptions, setRoofTypeFieldOptions] = useState<FormikAutocompleteOptionTypes[]>([]);
  const [scaffoldGroupFieldOptions, setScaffoldGroupFieldOptions] = useState<FormikAutocompleteOptionTypes[]>([]);
  const [mapMarker, setMapMarker] = useState<google.maps.Marker | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const initialPosition = {
    lat: 12.9716,
    lng: 77.5946,
  };
  const [position, setPosition] = useState<GoogleMapPositionTypes>(initialPosition);
  const formikRef = useRef<FormikProps<CreateNewLeadFormFormikFieldTypes>>(null);
  const [address, setAddress] = useState<AddressResult>({} as AddressResult);
  const [showAlert, setShowAlert] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const AlertStyle = {
    width: isSmallScreen ? '100%' : '95%',
    fontSize: 12,
    '& .MuiAlert-message': {
      width: '100%',
      textAlign: 'center',
    },
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
    '& .MuiAlert-action': {
      alignItems: 'center',
    },
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCmxw8d96uQwl80ba-ka1Vdj9H9JHddwL0',
    libraries: libraries,
  });

  const initialValues: CreateNewLeadFormFormikFieldTypes = {
    firstName: '',
    lastName: '',
    email: '',
    interestType: null,
    roofType: null,
    contactNo: '',
    zipCode: '',
    propertyAddress: '',
    hasTaskSequence: true,
    taskScheduleScaffoldGroup: null,
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email().required('Email is required'),
    contactNo: Yup.string()
      .required('Contact number is required')
      .test('is-valid-phone', 'Contact number is invalid', value =>
        matchIsValidTel(value ?? '', { onlyCountries: ['US', 'PH'] }),
      ),
    interestType: Yup.object().required('Interest type is required'),
    roofType: Yup.object().required('Roof type is required'),
    hasTaskSequence: Yup.boolean(),
    taskScheduleScaffoldGroup: Yup.object()
      .nullable()
      .when('hasTaskSequence', {
        is: true,
        then: () => Yup.object().required('Task schedule scaffold group is required'),
      }),
    propertyAddress: Yup.string().required('Property address is required'),
  });

  useQuery<CrmInterestTypes>(GET_INTEREST_TYPES_NEW_LEADS, {
    onCompleted: data => {
      const interestTypes = data.crm.preferences.interestTypes.map(item => ({
        label: item.interestTypeName === 'HVAC' ? item.interestTypeName : stringFormatter(item.interestTypeName),
        value: item.interestTypeName,
        id: item.id,
      })) as FormikAutocompleteOptionTypes[];

      //Filter out other options on dropdown
      if (interestTypes && interestTypes.length) {
        const interestTypeFilter = ['Solar Roof Hvac', 'Solar Hvac', 'Solar Roof'];
        const filteredInterestTypeOptions = interestTypes.filter(item => !interestTypeFilter.includes(item.label));
        setInterestTypeFieldOptions(filteredInterestTypeOptions);
        setScaffoldGroupFieldOptions(filteredInterestTypeOptions);
      }
    },
  });

  useQuery<CrmRoofTypes>(GET_ROOF_TYPES_NEW_LEADS, {
    onCompleted: data => {
      const roofTypeOptions = data.crm.preferences.roofTypes.map(item => ({
        label: stringFormatter(item.roofTypeName as string),
        value: item.roofTypeName,
        id: item.id,
      })) as FormikAutocompleteOptionTypes[];

      setRoofTypeFieldOptions(roofTypeOptions);
    },
  });

  useEffect(() => {
    if (selectedInterestType) {
      // Filter scaffold groups based on the selected interest type
      const filteredOptions = interestTypeFieldOptions.filter(item => item.value === selectedInterestType.value);
      setScaffoldGroupFieldOptions(filteredOptions);
    } else {
      setScaffoldGroupFieldOptions([]);
    }
    // Clear the value of taskScheduleScaffoldGroup when interest type changes
    formikRef?.current?.setFieldValue('taskScheduleScaffoldGroup', null);
  }, [selectedInterestType, interestTypeFieldOptions]);

  const handleOnLeadCreationSuccess = async () => {
    if (formikRef.current) {
      setShowAlert(true);
      formikRef.current.setSubmitting(false);
      toggleState();
      formikRef.current.resetForm();
      formikRef.current.setStatus({ sent: true });
      setMessage('Lead added successfully!');

      enqueueSnackbar('Lead created', {
        variant: 'success',
        persist: false,
        action: key => (
          <IconButton title="Close sucess" onClick={() => closeSnackbar(key)}>
            <CloseIcon color="disabled" />
          </IconButton>
        ),
      });

      navigate('/crm/leads');
    }
  };

  const handleSubmitForm = async (_values: FormikValues, { setErrors, setStatus, setSubmitting }: any) => {
    setErrors({ submit: '' });

    let isSuccess = false;
    let leadCreationResult = undefined;

    try {
      leadCreationResult = await createLead({
        variables: {
          input: {
            name: `${_values.firstName} ${_values.lastName}`,
            email: _values.email,
            interestType: _values.interestType.value,
            roofType: _values.roofType.value,
            contactNo: _values.contactNo,
            zipCode: address.zipCode,
            address: {
              streetNo: address.streetNumber,
              streetName: address.streetName,
              city: address.city,
              state: address.state,
              zipCode: address.zipCode,
              country: address.country,
              lat: address.latitude.toString(),
              lng: address.longitude.toString(),
            },
            hasTaskSequence: _values.hasTaskSequence,
            taskScheduleScaffoldGroup: _values.taskScheduleScaffoldGroup.value,
            assignedToId: userID,
            assignedById: userID,
          },
        },
      });

      isSuccess = leadCreationResult?.data?.crm.lead.createLead.success ? true : false;

      if (isSuccess) {
        handleOnLeadCreationSuccess();
      }

      return;
    } catch (error) {
      isSuccess = leadCreationResult?.data?.crm.lead.createLead.success ? true : false;

      setShowAlert(true);
      setSubmitting(false);
      toggleState();

      if (isSuccess) {
        handleOnLeadCreationSuccess();
      }

      if (error instanceof Error) {
        setStatus({ sent: false });
        setMessage(error.message);
        setErrors({ submit: error.message });
      }

      return;
    }
  };

  const handleOnMapMarkerOnChange = (googleMapMarkerInit: google.maps.Marker | null) => {
    setMapMarker(googleMapMarkerInit);
  };

  const onSearchBoxLoad = (googleMapsSearchBoxRef: google.maps.places.SearchBox) => {
    setSearchBox(googleMapsSearchBoxRef);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleOnMapOnChange = (googleMapInit: google.maps.Map | null) => {
    setMap(googleMapInit);
  };

  const changeMapLocation = (newPosition: GoogleMapPositionTypes) => {
    setPosition(newPosition);
    if (map) {
      map.panTo(newPosition);
    }
  };

  const updateAddress = (location: { lat: number; lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        const getAddress: AddressResult = getMapAddress(results[0]);
        setAddress(getAddress);

        const formattedAddress = `${getAddress.streetNumber} ${getAddress.streetName} ${getAddress.city} ${getAddress.state} ${getAddress.zipCode} ${getAddress.country}`;
        formikRef.current?.setFieldValue('propertyAddress', formattedAddress.trim());
      }
    });
  };

  const handlePlaceChanged = () => {
    const places = searchBox?.getPlaces();
    if (places?.[0]?.geometry?.location) {
      const nextCenter = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      };
      updateAddress(nextCenter);
      changeMapLocation(nextCenter);
    }
  };

  const handleDragEnd = async (e: google.maps.MapMouseEvent) => {
    if (mapMarker && e && e.latLng) {
      updateAddress({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    }
  };

  const leadMapProps: LeadMapProps = {
    position: position,
    useMarker: true,
    mapContainerStyle: {
      height: '480px',
      width: '100%',
    },
    handleOnMapOnChange: handleOnMapOnChange,
    handleOnMapMarkerOnChange: handleOnMapMarkerOnChange,
    handleDragEnd: handleDragEnd,
    options: {
      streetViewControl: true,
    },
    zoom: 20,
    showAddressLabel: true,
  };

  if (loadError || !isLoaded) {
    return (
      <React.Fragment>
        <NucleusLoader />
      </React.Fragment>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
      innerRef={formikRef}
    >
      {({ errors, status, handleBlur, handleChange, handleSubmit, touched, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Basic Contact Information
            </Typography>
            <Typography variant="body2" gutterBottom>
              Provide details to get started.
            </Typography>
            <Grid container columnSpacing={6} rowSpacing={2}>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="firstName"
                  label="First Name"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="lastName"
                  label="Last Name"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextField
                  field="email"
                  label="Email"
                  touched={touched}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field name="contactNo" component={PhoneNumberInput} onlyCountries={['US', 'PH']} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="interestType"
                  component={FormikAutoComplete}
                  label="Interest Type"
                  options={interestTypeFieldOptions}
                  touched={touched}
                  errors={errors}
                  textFieldProps={{
                    fullWidth: true,
                    margin: 'dense',
                    variant: 'outlined',
                    autoFocus: false,
                  }}
                  setFieldValue={setSelectedInterestType}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  name="roofType"
                  component={FormikAutoComplete}
                  label="Roof Type"
                  options={roofTypeFieldOptions}
                  touched={touched}
                  errors={errors}
                  textFieldProps={{
                    fullWidth: true,
                    margin: 'dense',
                    variant: 'outlined',
                    autoFocus: false,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Field
                  component={FormikCheckbox}
                  name="hasTaskSequence"
                  label="Enroll In Task Sequence"
                  touched={touched}
                  errors={errors}
                  values={values}
                  disabled={true}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                />
              </Grid>
              {values.hasTaskSequence && (
                <Grid item xs={12} md={4}>
                  <Field
                    name="taskScheduleScaffoldGroup"
                    component={FormikAutoComplete}
                    label="Scaffold Group"
                    options={scaffoldGroupFieldOptions}
                    touched={touched}
                    errors={errors}
                    textFieldProps={{
                      fullWidth: true,
                      margin: 'dense',
                      variant: 'outlined',
                      autoFocus: false,
                    }}
                  />
                </Grid>
              )}
              <Grid item container xs={12} rowSpacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="propertyAddress"
                    label="Property Address"
                    component={FormikGoogleMapsSearchBox}
                    touched={touched}
                    errors={errors}
                    values={values}
                    textFieldProps={
                      {
                        fullWidth: true,
                        margin: 'dense',
                        variant: 'outlined',
                        autoFocus: false,
                        InputProps: undefined,
                        sx: undefined,
                        placeholder: 'Property Address',
                      } as TextFieldProps
                    }
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    onKeyDown={handleKeyDown}
                    handlePlaceChanged={handlePlaceChanged}
                    onSearchBoxLoad={onSearchBoxLoad}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid item container flexDirection={'column'} xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Property Address
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Indicate details for property.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LeadMap {...leadMapProps} />
                </Grid>
                <Grid item container xs={12} rowSpacing={2}>
                  <Grid item container justifyContent={'center'} alignItems={'center'} xs={12} sm={'auto'}>
                    <Button type="submit" variant="contained" color="primary" fullWidth={isSmallScreen}>
                      {isSubmitting ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <NucleusLoader type={'circular'} sx={{ color: 'white', marginRight: 2 }} size="1rem" />{' '}
                          Creating Lead
                        </Box>
                      ) : (
                        'Create Lead'
                      )}
                    </Button>
                  </Grid>
                  <Grid item container justifyContent={'center'} xs={12} sm>
                    {showAlert && status && status.sent && (
                      <Alert
                        severity="success"
                        sx={AlertStyle}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        Lead successfully created
                      </Alert>
                    )}
                    {showAlert && errors && errors.submit && (
                      <Alert
                        severity="error"
                        sx={AlertStyle}
                        onClose={() => {
                          setShowAlert(false);
                        }}
                      >
                        {errors.submit}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      )}
    </Formik>
  );
}

function NewLead() {
  return (
    <React.Fragment>
      <PageHeader />
      <LeadForm />
    </React.Fragment>
  );
}

export default NewLead;
