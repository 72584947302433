import React, { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CrmUser } from '../types/crm/user';
import { NotificationType } from '../types/conversation';
import { IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { Call, Device } from '@twilio/voice-sdk';
import IncomingCall from '../components/voiceCall/IncomingCall';
import { UserIdentityContext } from './UserIdentityContext';
import { CallStatusType } from '../pages/task/components/VoiceCallComponent';
import IncomingCallMini from '../components/voiceCall/IncomingCallMini';

export type VoiceTokenContextProps = {
  voiceTokenContext: string | undefined;
  setVoiceTokenContext: (token: string) => void;
  deviceContext: Device | undefined;
  setDeviceContext: (device: Device) => void;
  callInstanceContext: Call | null;
  setCallInstanceContext: (callInstance: Call | null) => void;
  isIncomingCall: boolean;
  setIsIncomingCall: (isIncoming: boolean) => void;
  isAnswered: boolean;
  setIsAnswered: (isAnswered: boolean) => void;
  isMinimizedIncomingCall: boolean;
  setIsMinimizedIncomingCall: (isMinimized: boolean) => void;
  incomingCallStatusContext: CallStatusType | undefined;
  setIncomingCallStatusContext: (incomingCallStatus: CallStatusType | undefined) => void;
  acceptIncomingCall: () => Promise<void>;
  rejectIncomingCall: () => Promise<void>;
  isMicEnabled: boolean;
  setIsMicEnabled: (isMicEnabled: boolean) => void;
};

export const VoiceTokenContext = React.createContext<VoiceTokenContextProps | undefined>(undefined);

export const VoiceTokenProvider = ({ children }: { children: ReactNode }) => {
  const [voiceTokenContext, setVoiceTokenContext] = React.useState<string>('');
  const [deviceContext, setDeviceContext] = useState<Device>();
  const [callInstanceContext, setCallInstanceContext] = useState<Call | null>(null);
  const [isIncomingCall, setIsIncomingCall] = useState<boolean>(false);
  const { userIdentity } = React.useContext(UserIdentityContext) || {};
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [incomingCallStatusContext, setIncomingCallStatusContext] = useState<CallStatusType | undefined>(undefined);
  const [isMinimizedIncomingCall, setIsMinimizedIncomingCall] = useState<boolean>(false);
  const [isMicEnabled, setIsMicEnabled] = useState<boolean>(false);

  const handleNotification = ({ message, variant }: NotificationType) => {
    enqueueSnackbar(message, {
      variant: variant,
      persist: true,
      action: key => (
        <IconButton title="Close error" onClick={() => closeSnackbar(key)}>
          <GridCloseIcon color="disabled" />
        </IconButton>
      ),
    });
  };

  const handleIncomingCall = async (activeCall: Call) => {
    setCallInstanceContext(activeCall);
    setIsAnswered(false);
    setIsIncomingCall(true);
    setIncomingCallStatusContext(CallStatusType.Ringing);
    setIsMinimizedIncomingCall(false);

    activeCall.on('accept', () => {
      setIsAnswered(true);
      setIncomingCallStatusContext(CallStatusType.InProgress);
    });

    activeCall.on('cancel', () => {
      setIsIncomingCall(false);
    });

    activeCall.on('disconnect', () => {
      setIsIncomingCall(false);
      setIncomingCallStatusContext(undefined);
    });

    activeCall.on('reject', () => {
      setIsIncomingCall(false);
      setIncomingCallStatusContext(undefined);
    });
  };

  const getVoiceToken = async (currentUser: CrmUser) => {
    const accessToken = await currentSession();
    if (userIdentity) {
      const tokenRes = await axios.get(
        `/token?identity=${userIdentity.id}&number=${currentUser?.twilio?.projectedAddress}`,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        },
      );

      if (tokenRes.data.token) {
        return tokenRes.data.token;
      } else {
        handleNotification({
          message: 'An error occurred while retrieving the access token.',
          variant: 'error',
        });
      }
    }
  };

  const initializeDevice = async (token: string) => {
    const device = new Device(token, { tokenRefreshMs: 30000 });
    await device.register();
    device.on('incoming', handleIncomingCall);
    device.on('tokenWillExpire', async () => {
      const newToken = await getVoiceToken(userIdentity!);
      device.updateToken(newToken);
    });
    setDeviceContext(device);
    setIsMinimizedIncomingCall(false);
  };

  const acceptIncomingCall = async () => {
    if (callInstanceContext) {
      const status = await navigator.permissions.query({ name: 'microphone' as PermissionName });
      if (status.state !== 'granted') {
        return;
      }
      callInstanceContext.accept();
    }
  };

  const rejectIncomingCall = async () => {
    if (callInstanceContext) {
      callInstanceContext.reject();

      if (isAnswered) {
        callInstanceContext.disconnect();
        setIsAnswered(false);
      }
    }
  };

  const showIncomingCall = () => {
    if (isIncomingCall) {
      return !isMinimizedIncomingCall;
    }
    return false;
  };

  const showIncomingCallMini = () => {
    if (isIncomingCall) {
      return isMinimizedIncomingCall;
    }
    return false;
  };

  useEffect(() => {
    if (userIdentity) {
      const getToken = async () => {
        const initToken = await getVoiceToken(userIdentity);
        await initializeDevice(initToken);
      };
      getToken();
    }
  }, [userIdentity]); //eslint-disable-line react-hooks/exhaustive-deps

  async function currentSession() {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      return accessToken;
    } catch (err) {
      return null;
    }
  }

  return (
    <VoiceTokenContext.Provider
      value={{
        voiceTokenContext,
        setVoiceTokenContext,
        deviceContext,
        setDeviceContext,
        callInstanceContext,
        setCallInstanceContext,
        isIncomingCall,
        setIsIncomingCall,
        isAnswered,
        setIsAnswered,
        isMinimizedIncomingCall,
        setIsMinimizedIncomingCall,
        incomingCallStatusContext,
        setIncomingCallStatusContext,
        acceptIncomingCall,
        rejectIncomingCall,
        isMicEnabled,
        setIsMicEnabled,
      }}
    >
      {children}
      {showIncomingCall() && <IncomingCall />}
      {showIncomingCallMini() && <IncomingCallMini />}
    </VoiceTokenContext.Provider>
  );
};
