import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';

import { Alert, Box, CssBaseline, LinearProgress, Paper as MuiPaper } from '@mui/material';
import { spacing } from '@mui/system';

import GlobalStyle from '../../components/GlobalStyle';
import Navbar from '../../components/navbar/Navbar';
import dashboardItems from './dashboardItems';
import Sidebar from '../../components/sidebar/Sidebar';
import Footer from '../../components/Footer';
import Settings from '../../components/Settings';
import { useQuery } from '@apollo/client';
import { CrmUser, IdentityUsers } from '../types/crm/user';

import { AuthContext } from '../../contexts/CognitoContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { GET_USER } from '../../common/graphQL';
import MicrophonePermissionChecker from '../components/voiceCall/MicrophonePermissionChecker';

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.default,
  height: 'calc(100vh - 64px)', // Assuming the header height is 64px
  overflow: 'auto',
  [theme.breakpoints.up('md')]: {
    width: 'calc(100vw - 260px)', // Assuming the navigation width is 260px on non-mobile
  },
  [theme.breakpoints.down('md')]: {
    width: '100vw', // Full width on mobile
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    flex: 'none',
  },
  '& .MuiPaper-root .MuiPaper-root': {
    boxShadow: 'none',
  },
}));

interface DashboardType {
  children?: React.ReactNode;
}

/**
 *
 * @param root0
 */
const Dashboard: React.FC<DashboardType> = ({ children }) => {
  const { user } = useContext(AuthContext) || {};
  const userID = user && user['custom:nucleus-id'];
  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showSendGridEmailReminder, setShowSendGridEmailReminder] = useState(false);

  const { data: usersDataQueryResult, loading } = useQuery<IdentityUsers>(GET_USER, {
    variables: {
      filter: {
        userId: userID,
      },
    },
    onCompleted: data => {
      const isSendGridVerified = data.identity?.users[0]?.twilio?.sendGridVerified ?? false;
      setShowSendGridEmailReminder(!isSendGridVerified);

      if (!isDataFetched) {
        setIsDataFetched(true);
      }
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  if (loading || !usersDataQueryResult) {
    return <LinearProgress />;
  }

  return (
    <Root>
      <CurrentUserContext.Provider value={usersDataQueryResult.identity?.users[0] ?? ({} as CrmUser)}>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar PaperProps={{ style: { width: drawerWidth } }} items={dashboardItems} />
          </Box>
        </Drawer>
        <AppContent>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <MicrophonePermissionChecker />
          {showSendGridEmailReminder && (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ borderRadius: 0 }}
              onClose={() => {
                setShowSendGridEmailReminder(false);
              }}
            >
              Please verify your email address for SendGrid. Thank you!
            </Alert>
          )}
          <MainContent sx={{ p: 5 }}>
            {children}
            <Outlet />
          </MainContent>
          <Footer />
        </AppContent>
        <Settings />
      </CurrentUserContext.Provider>
    </Root>
  );
};

export default Dashboard;
