import React, { FC, useEffect, useState } from 'react';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Env, fetchEnv } from '../utils/getEnv';

type HubProviderProps = {
  children: React.ReactNode;
};

export const HubProvider: FC<HubProviderProps> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null);

  async function currentSession() {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      return accessToken;
    } catch (err) {
      return null;
    }
  }

  useEffect(() => {
    async function initializeClient() {
      const accessToken = await currentSession();
      const env: Env = await fetchEnv();

      const httpLink = createHttpLink({
        uri: env.hub_url,
        headers: {
          Authorization: `${accessToken}`,
        },
      });

      const apolloClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
      });

      setClient(apolloClient);
    }

    initializeClient();
  }, []);

  if (!client) {
    return <div>Loading...</div>;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
