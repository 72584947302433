import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import { Voicemail as VoicemailIcon } from 'react-feather';
import NucleusPage from '../components/NucleusPage';
import { DataGrid, GridCellParams, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import EllipsisText from '../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../components/DateFormatter';
import { formatDuration } from '../../helpers/durationFormatter';
import { grey } from '@mui/material/colors';
import VoicemailModal from './voicemail/components/VoicemailModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_CALL_RECORDINGS_PAGED } from '../../common/graphQL/call/callRecording/queries';
import {
  CrmCallRecording,
  CrmCallRecordingsPaged,
  CrmCallRecordingTypeEnum,
  mapCallRecordingCallerData,
} from '../types/crm/callRecording';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import LetterAvatar from '../components/Avatar';
import { setVoicemailsData, setIsDataLoaded, setTotalVoicemails } from '../../redux/twilio/voiceMailSlice';
import { ArrowBack, Refresh } from '@mui/icons-material';
import CustomNoRowsOverlay from '../components/advanceSearchGrid/CustomNoRowsOverlay';

function Voicemail() {
  const currentUser = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const { paramVoicemailSid } = useParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAllListBtn, enableShowAllListBtn] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25, // Adjust according to your needs
  });
  const [selectedVoicemail, setSelectedVoicemail] = useState<CrmCallRecording | undefined>(undefined);
  const isDataLoaded = useAppSelector((state: RootState) => state.voicemails.isDataLoaded);
  const voicemailData = useAppSelector((state: RootState) => state.voicemails.data);
  const voicemailCount = useAppSelector<number>((state: RootState) => state.voicemails.totalVoicemails);
  const dispatch = useAppDispatch();

  const [getVoicemails, { data: resultsVoicemail, loading: isLoading, error: errorResults }] =
    useLazyQuery<CrmCallRecordingsPaged>(GET_CALL_RECORDINGS_PAGED, {
      variables: {
        filter: {
          type: CrmCallRecordingTypeEnum.VOICEMAIL,
          receiver: currentUser?.twilio.projectedAddress!,
          ...(paramVoicemailSid && { callSid: paramVoicemailSid }), // Add this line to conditionally include 'sid' filter
        },
        pagination: {
          offset: paginationModel.page * paginationModel.pageSize, // GraphQL pagination is usually 1-indexed
          limit: paginationModel.pageSize,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        dispatch(setVoicemailsData([]));
        dispatch(setIsDataLoaded(true));
      },
    });

  useEffect(() => {
    getVoicemails();
  }, []);

  useEffect(() => {
    if (resultsVoicemail && resultsVoicemail?.crm?.userOptions?.callRecordingsPaged?.callRecordings) {
      dispatch(setVoicemailsData(resultsVoicemail?.crm?.userOptions?.callRecordingsPaged?.callRecordings));
      dispatch(setIsDataLoaded(true));
      dispatch(setTotalVoicemails(resultsVoicemail?.crm?.userOptions?.callRecordingsPaged?.totalCount));

      // Find the voicemail with the specific sid
      const matchedVoicemail = resultsVoicemail?.crm?.userOptions?.callRecordingsPaged?.callRecordings?.find(
        voicemail => voicemail.callSid === paramVoicemailSid,
      );

      // If there's a match, set it as the selected voicemail and open the modal
      if (matchedVoicemail) {
        setSelectedVoicemail(matchedVoicemail);
        setShowModal(true); // Open modal
        enableShowAllListBtn(true); // Enable the 'Show All' button
      }
    }

    if (resultsVoicemail?.crm?.userOptions?.callRecordingsPaged?.callRecordings.length === 0 && paramVoicemailSid) {
      setSelectedVoicemail(undefined);
      setShowModal(true); // Open modal
      enableShowAllListBtn(true); // Enable the 'Show All' button
    }
  }, [resultsVoicemail, paramVoicemailSid, dispatch]);

  const handleViewVoicemail = (row: CrmCallRecording | undefined) => {
    setShowModal(true);
    setSelectedVoicemail(row!);
  };

  const columns: GridColDef[] = [
    {
      field: 'caller',
      headerName: 'From',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        const name =
          params.row.callerData && params.row.callerData.__typename === 'CrmUser'
            ? `${mapCallRecordingCallerData(params?.row?.callerData)?.userFirstName ?? ''} ${
                mapCallRecordingCallerData(params?.row?.callerData)?.userLastName ?? ''
              }`
            : params.row.callerData && params.row.callerData.__typename === 'CrmLead'
              ? `${mapCallRecordingCallerData(params?.row?.callerData)?.leadName ?? ''}`
              : '';
        const contactNo = params.row?.dialer ?? '';
        return (
          <React.Fragment>
            {params.row.lead || params.row.dialer ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {name && <LetterAvatar name={name ?? ''} sx={{ backgroundColor: 'orange' }} />}
                <Box sx={{ pl: 2, flex: 1, display: 'inline-grid' }}>
                  {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
                  {contactNo ? <EllipsisText content={contactNo} /> : <EllipsisText content={'Not Provided'} />}
                </Box>
              </Box>
            ) : (
              <EllipsisText content="Not Provided" />
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'mediaUrl',
      headerName: 'Voicemail',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.mediaUrl) {
          return 'None';
        }

        return (
          <audio controls style={{ width: '100%' }}>
            <source src={params.row.mediaUrl!} />
          </audio>
        );
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdAt) {
          return 'None';
        }

        return <EllipsisText content={formatDuration(params.row?.duration, true)} />;
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdAt) {
          return 'None';
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: params.row.createdAt,
              dateFormatType: DateFormatType.Standard,
            })}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <Button size={'small'} variant={'contained'} onClick={() => handleViewVoicemail(params.row)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <NucleusPage
      title={'Voicemails'}
      header={`${currentUser?.firstName!}'s Voicemails`}
      loading={!isDataLoaded}
      content={
        <React.Fragment>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {showAllListBtn && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    navigate(`/crm/calls/voicemails`, {
                      state: {
                        paramVoicemailSid: null,
                        setSelectedVoicemail: undefined,
                      },
                    });
                    enableShowAllListBtn(false);
                  }}
                  startIcon={<ArrowBack />}
                >
                  Return to Main Page
                </Button>
              )}
            </Grid>
          </Grid>
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <DataGrid
                  rowCount={voicemailCount}
                  paginationMode="server"
                  pagination
                  loading={isLoading}
                  rows={voicemailData ?? []}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  disableRowSelectionOnClick
                  onPaginationModelChange={setPaginationModel}
                  columns={columns}
                  rowHeight={80}
                  slots={{
                    noRowsOverlay: () => (
                      <CustomNoRowsOverlay
                        children={
                          <React.Fragment>
                            <VoicemailIcon color={grey[400]} width={50} height={50} />
                            <Box sx={{ mt: 1 }}>
                              {errorResults ? (
                                <>
                                  <Box>{'Something went wrong when retrieving voicemails. Please try reloading!'}</Box>
                                  <Box display={'flex'} justifyContent={'center'}>
                                    <Button
                                      variant="text"
                                      size={'small'}
                                      startIcon={<Refresh />}
                                      onClick={() => getVoicemails()}
                                    >
                                      Reload
                                    </Button>
                                  </Box>
                                </>
                              ) : (
                                'Currently, you do not have any voicemail.'
                              )}
                            </Box>
                          </React.Fragment>
                        }
                      />
                    ),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
          <VoicemailModal
            open={showModal}
            onClose={() => setShowModal(false)}
            data={selectedVoicemail}
            param={paramVoicemailSid}
          />
        </React.Fragment>
      }
    />
  );
}

export default Voicemail;
