import React, { createContext, useState, useContext, ReactNode } from 'react';
import EnhancedSnackbar from '../components/Snackbar';

type SnackbarContextProps = {
  isOpen: boolean;
  toggleState: () => void;
  setMessage: (message: string) => void;
  originVertical: 'top' | 'bottom';
  originHorizontal: 'left' | 'center' | 'right';
  message: string;
};

const SnackbarContext = createContext<SnackbarContextProps>({
  isOpen: false,
  toggleState: () => {},
  setMessage: () => {},
  originHorizontal: 'left',
  originVertical: 'bottom',
  message: '',
});

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, toggleAlertState] = useState(false);
  const [originVertical] = useState<'top' | 'bottom'>('bottom');
  const [originHorizontal] = useState<'left' | 'center' | 'right'>('center');
  const [message, setMessageState] = useState<string>('Lead Successfully Added!');

  const toggleState = () => {
    toggleAlertState(!isOpen);
  };

  const setMessage = () => {
    setMessageState(message);
  };

  return (
    <SnackbarContext.Provider value={{ isOpen, toggleState, originVertical, originHorizontal, setMessage, message }}>
      {children}
      {isOpen && <EnhancedSnackbar></EnhancedSnackbar>}
    </SnackbarContext.Provider>
  );
};
