import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import NucleusPage from '../components/NucleusPage';
import { DataGrid, GridCellParams, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import EllipsisText from '../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../components/DateFormatter';
import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import { PhoneMissed } from 'react-feather';
import { grey } from '@mui/material/colors';
import { useNavigate, useParams } from 'react-router-dom';
import MissedCallModal from './missedCall/components/MissedCallModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { useLazyQuery } from '@apollo/client';
import {
  CallDirectionEnum,
  CallPayloadStatusEnum,
  CrmCallPayload,
  CrmCallPayloadPaged,
  mapCallPayloadCallerData,
} from '../types/crm/callPayload';
import { GET_CALL_PAYLOADS_PAGED } from '../../common/graphQL/call/callPayload/queries';
import { setCallPayloadsData, setIsDataLoaded, setTotalCallPayloads } from '../../redux/twilio/callPayloadSlice';
import LetterAvatar from '../components/Avatar';
import CustomNoRowsOverlay from '../components/advanceSearchGrid/CustomNoRowsOverlay';
import { ArrowBack, Refresh } from '@mui/icons-material';

function MissedCall() {
  const currentUser = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const { paramMissedCallId } = useParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAllListBtn, enableShowAllListBtn] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25, // Adjust according to your needs
  });
  const [selectedMissedCall, setSelectedMissedCall] = useState<CrmCallPayload | undefined>(undefined);
  const isDataLoaded = useAppSelector((state: RootState) => state.callPayloads.isDataLoaded);
  const missedCallData = useAppSelector((state: RootState) => state.callPayloads.data);
  const missedCallCount = useAppSelector<number>((state: RootState) => state.callPayloads.totalCallPayloads);
  const dispatch = useAppDispatch();

  const [getMissedCalls, { data: resultsCallPayload, loading: isLoading, error: errorResults }] =
    useLazyQuery<CrmCallPayloadPaged>(GET_CALL_PAYLOADS_PAGED, {
      variables: {
        filter: {
          direction: CallDirectionEnum.INBOUND,
          receiver: currentUser?.twilio.projectedAddress!,
          status: [
            CallPayloadStatusEnum.BUSY,
            CallPayloadStatusEnum.CANCELED,
            CallPayloadStatusEnum.NO_ANSWER,
            CallPayloadStatusEnum.FAILED,
          ],
          ...(paramMissedCallId && { callPayloadId: parseInt(paramMissedCallId) }), // Add this line to conditionally include 'sid' filter
        },
        pagination: {
          offset: paginationModel.page * paginationModel.pageSize, // GraphQL pagination is usually 1-indexed
          limit: paginationModel.pageSize,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        dispatch(setCallPayloadsData([]));
        dispatch(setIsDataLoaded(true));
      },
    });

  useEffect(() => {
    getMissedCalls();
  }, []);

  useEffect(() => {
    if (resultsCallPayload && resultsCallPayload?.crm?.userOptions?.callPayloadsPaged?.callPayloads) {
      dispatch(setCallPayloadsData(resultsCallPayload?.crm?.userOptions?.callPayloadsPaged?.callPayloads));
      dispatch(setIsDataLoaded(true));
      dispatch(setTotalCallPayloads(resultsCallPayload?.crm?.userOptions?.callPayloadsPaged?.totalCount));

      // Find the missed call with the specific sid
      const matchedCallPayload = resultsCallPayload?.crm?.userOptions?.callPayloadsPaged?.callPayloads?.find(
        call => call.id.toString() === paramMissedCallId,
      );

      // If there's a match, set it as the selected missed call and open the modal
      if (matchedCallPayload) {
        setSelectedMissedCall(matchedCallPayload);
        setShowModal(true); // Open modal
        enableShowAllListBtn(true); // Enable the 'Show All' button
      }
    }

    if (resultsCallPayload?.crm?.userOptions?.callPayloadsPaged?.callPayloads.length === 0 && paramMissedCallId) {
      setSelectedMissedCall(undefined);
      setShowModal(true); // Open modal
      enableShowAllListBtn(true); // Enable the 'Show All' button
    }
  }, [resultsCallPayload, paramMissedCallId, dispatch]);

  const handleViewMissedCall = (row: CrmCallPayload | undefined) => {
    setShowModal(true);
    setSelectedMissedCall(row!);
  };

  const columns: GridColDef[] = [
    {
      field: 'caller',
      headerName: 'From',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        const name =
          params.row.callerData && params.row.callerData.__typename === 'CrmUser'
            ? `${mapCallPayloadCallerData(params?.row?.callerData)?.userFirstName ?? ''} ${
                mapCallPayloadCallerData(params?.row?.callerData)?.userLastName ?? ''
              }`
            : params.row.callerData && params.row.callerData.__typename === 'CrmLead'
              ? `${mapCallPayloadCallerData(params?.row?.callerData)?.leadName ?? ''}`
              : '';
        const contactNo = params.row?.caller ?? '';
        return (
          <React.Fragment>
            {params?.row?.callerData ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {name && <LetterAvatar name={name ?? ''} sx={{ backgroundColor: 'orange' }} />}
                <Box sx={{ pl: 2, flex: 1, display: 'inline-grid' }}>
                  {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
                  {contactNo ? <EllipsisText content={contactNo} /> : <EllipsisText content={'Not Provided'} />}
                </Box>
              </Box>
            ) : (
              <>{contactNo ? <EllipsisText content={contactNo} /> : <EllipsisText content={'Not Provided'} />}</>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdAt) {
          return 'None';
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: params.row.createdAt,
              dateFormatType: DateFormatType.Standard,
            })}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <Button size={'small'} variant={'contained'} onClick={() => handleViewMissedCall(params.row)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <NucleusPage
      title="Missed Calls"
      header={`${currentUser?.firstName}'s Missed Calls`}
      loading={!isDataLoaded}
      content={
        <React.Fragment>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {showAllListBtn && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    navigate(`/crm/calls/missed-calls`, {
                      state: {
                        paramMissedCallSid: null,
                        setSelectedMissedCall: undefined,
                      },
                    });
                    enableShowAllListBtn(false);
                  }}
                  startIcon={<ArrowBack />}
                >
                  Return to Main Page
                </Button>
              )}
            </Grid>
          </Grid>
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <DataGrid
                  rowCount={missedCallCount}
                  paginationMode="server"
                  pagination
                  loading={isLoading}
                  rows={missedCallData ?? []}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  disableRowSelectionOnClick
                  onPaginationModelChange={setPaginationModel}
                  columns={columns}
                  slots={{
                    noRowsOverlay: () => (
                      <CustomNoRowsOverlay
                        children={
                          <React.Fragment>
                            <PhoneMissed color={grey[400]} width={50} height={50} />
                            <Box sx={{ mt: 1 }}>
                              {errorResults ? (
                                <>
                                  <Box>
                                    {'Something went wrong when retrieving missed calls. Please try reloading!'}
                                  </Box>
                                  <Box display={'flex'} justifyContent={'center'}>
                                    <Button
                                      variant="text"
                                      size={'small'}
                                      startIcon={<Refresh />}
                                      onClick={() => getMissedCalls()}
                                    >
                                      Reload
                                    </Button>
                                  </Box>
                                </>
                              ) : (
                                'Currently, you do not have any missed call.'
                              )}
                            </Box>
                          </React.Fragment>
                        }
                      />
                    ),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
          <MissedCallModal
            open={showModal}
            onClose={() => setShowModal(false)}
            data={selectedMissedCall}
            param={paramMissedCallId}
          />
        </React.Fragment>
      }
    />
  );
}

export default MissedCall;
