import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import NucleusPage from '../components/NucleusPage';
import { DataGrid, GridCellParams, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import EllipsisText from '../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../components/DateFormatter';
import { Box, Button, Card, CardContent } from '@mui/material';
import { PhoneMissed } from 'react-feather';
import { grey } from '@mui/material/colors';
import { useLazyQuery } from '@apollo/client';
import { CallPayloadStatusEnum } from '../types/crm/callPayload';
import LetterAvatar from '../components/Avatar';
import CustomNoRowsOverlay from '../components/advanceSearchGrid/CustomNoRowsOverlay';
import { Refresh } from '@mui/icons-material';
import { ContactDetails } from './contacts/profile/ContactDetails';
import { formatDuration } from '../../helpers/durationFormatter';
import CallStatusIcon from './callActivity/CallActivityCallStatusIcon';
import { GET_CALL_RECORDINGS_PAYLOADS_PAGED } from '../../common/graphQL/call/callActivity/queries';
import {
  callActivityMapCallPayloadToCallLogs,
  callActivityMapRecordingToCallLogs,
  CrmCallActivityCallLogs,
  CrmCallActivityCallPayload,
  CrmCallActivityCallRecording,
  CrmCallRecordingsAndPayloadsPaged,
} from '../types/crm/callActivity';
import CallActivitySearchBar from './callActivity/CallActivitySearchBar';

function CallActivity() {
  const currentUser = useContext(CurrentUserContext);

  const [searchString, setSearchString] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [allCallLogsData, setAllCallLogsData] = useState<CrmCallActivityCallLogs[]>([]);
  const [allCallLogsCount, setAllCallLogsCount] = useState<number>(0);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const [getAllCallLogs, { data: resultsAllCallLogs, loading: loadingAllCallLogs, error: errorResults }] =
    useLazyQuery<CrmCallRecordingsAndPayloadsPaged>(GET_CALL_RECORDINGS_PAYLOADS_PAGED, {
      variables: {
        filter: {
          receiver: searchString ?? currentUser?.twilio.projectedAddress!,
          caller: searchString ?? currentUser?.twilio.projectedAddress!,
          dialer: searchString ?? currentUser?.twilio.projectedAddress!,
          status: [
            CallPayloadStatusEnum.BUSY,
            CallPayloadStatusEnum.CANCELED,
            CallPayloadStatusEnum.NO_ANSWER,
            CallPayloadStatusEnum.FAILED,
            CallPayloadStatusEnum.COMPLETED,
          ],
        },
        pagination: {
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        // Handle error if needed
      },
    });

  const getAllCalls = () => {
    getAllCallLogs();
  };

  useEffect(() => {
    if (resultsAllCallLogs && !loadingAllCallLogs) {
      const callLogList: CrmCallActivityCallLogs[] = (
        resultsAllCallLogs?.crm?.userOptions?.callRecordingsAndPayloadsPage?.callRecordingsAndPayloads ?? []
      ).map((row: any) => {
        if (row.__typename === 'CrmCallPayload') {
          const callLog = callActivityMapCallPayloadToCallLogs(row as CrmCallActivityCallPayload);
          return callLog;
        } else {
          const callLog = callActivityMapRecordingToCallLogs(row as CrmCallActivityCallRecording);
          return callLog;
        }
      });

      setAllCallLogsData(callLogList ?? []);
      setAllCallLogsCount(resultsAllCallLogs?.crm?.userOptions?.callRecordingsAndPayloadsPage?.totalCount ?? 0);
    }
  }, [resultsAllCallLogs, loadingAllCallLogs]);

  useEffect(() => {
    getAllCalls();
  }, []);

  useEffect(() => {
    if (loadingAllCallLogs) {
      setIsFetchingData(true);
    } else {
      setIsFetchingData(false);
    }
  }, [loadingAllCallLogs]);

  const columns: GridColDef[] = [
    {
      field: 'callerName',
      headerName: 'Calls',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        const name = params.row.callerName ?? '';
        const contactNo = params.row?.caller ?? '';
        return (
          <React.Fragment>
            {
              <Box sx={{ mr: 5, display: 'inline-grid' }}>
                <CallStatusIcon status={params?.row?.status ?? ''} direction={params?.row?.direction} />
              </Box>
            }
            {params?.row?.callerName ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {name && <LetterAvatar name={name ?? ''} sx={{ backgroundColor: 'orange' }} />}
                <Box sx={{ pl: 1, flex: 1, display: 'inline-grid' }}>
                  {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
                </Box>
              </Box>
            ) : (
              <>{contactNo ? <EllipsisText content={contactNo} /> : <EllipsisText content={'Not Provided'} />}</>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdAt) {
          return 'None';
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: params.row.createdAt,
              dateFormatType: DateFormatType.LongDateWithTime,
            })}
          />
        );
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.duration) {
          return 'None';
        }

        const content =
          params.row.logType === 'voicemail'
            ? 'voicemail'
            : params.row.duration === '0'
              ? '--'
              : formatDuration(params.row.duration);

        return <EllipsisText content={content} />;
      },
    },
    {
      field: 'mediaUrl',
      headerName: 'Recording',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.mediaUrl) {
          return <EllipsisText content={'--'} />;
        }

        return (
          <audio controls style={{ width: '80%' }}>
            <source src={params.row.mediaUrl!} />
          </audio>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      minWidth: 300,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.hasCallerData) {
          return 'Contact features are unavailable.';
        }

        return (
          <React.Fragment>
            <ContactDetails currentLead={params.row.leadDetails} currentUser={currentUser!} onlyShowActions={true} />
          </React.Fragment>
        );
      },
    },
  ];

  const searchCallLogs = (searchValue: string) => {
    setSearchString(searchValue);
  };

  return (
    <NucleusPage
      title="Missed Calls"
      header={'Call Activity'}
      loading={isFetchingData}
      content={
        <React.Fragment>
          <CallActivitySearchBar placeholder={'Search Call Log'} searchData={searchCallLogs} />
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <DataGrid
                  rowCount={allCallLogsCount}
                  paginationMode="server"
                  pagination
                  loading={isFetchingData}
                  rows={allCallLogsData ?? []}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  disableRowSelectionOnClick
                  onPaginationModelChange={setPaginationModel}
                  columns={columns}
                  rowHeight={70}
                  slots={{
                    noRowsOverlay: () => (
                      <CustomNoRowsOverlay
                        children={
                          <React.Fragment>
                            <PhoneMissed color={grey[400]} width={50} height={50} />
                            <Box sx={{ mt: 1 }}>
                              {errorResults ? (
                                <>
                                  <Box>
                                    {'Something went wrong when retrieving call activity. Please try reloading!'}
                                  </Box>
                                  <Box display={'flex'} justifyContent={'center'}>
                                    <Button
                                      variant="text"
                                      size={'small'}
                                      startIcon={<Refresh />}
                                      onClick={() => getAllCallLogs()}
                                    >
                                      Reload
                                    </Button>
                                  </Box>
                                </>
                              ) : (
                                'Currently, you do not have any call activity.'
                              )}
                            </Box>
                          </React.Fragment>
                        }
                      />
                    ),
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </React.Fragment>
      }
    />
  );
}

export default CallActivity;
